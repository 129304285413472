// locales/ru.js

export default {
  choose_language: 'Выберите язык',
  btnIcon: require('../../public/flags-locales/ru.png'),
  features: 'Преимущества',
  services: 'Услуги',
  contacts: 'Контакты',
  catalog: 'Каталог',
  rental_service: 'Аренда ноутбуков',
  in_almaty: 'в Алматы',
  laptop_rental_in_almaty: 'Аренда ноутбуков в Алматы',
  the_best_choice_laptop: 'Выбор подходящего ноутбука',
  set_necessary_software: 'Установка необходимого ПО',
  delivery_or_pickup: 'Доставка или самовывоз',
  business_class_rental: '"Бизнес-класс" аренда',
  high_performance_laptops: 'Ноутбуки бизнес класса справятся с любыми задачами на отлично',
  laptop_catalog: 'Каталог ноутбуков',
  rental_cost: 'Стоимость аренды',
  rental_price: 'От 5 000 тг. и выше (в зависимости от срока аренды)',
  long_term_rental: 'Долгосрочная аренда',
  for_long_term_rent: 'При долгосрочной аренде',
  percent_sale: 'Вы можете рассчитывать на скидки до 80%',
  trust_us: 'Нам доверяют',
  samal_address: 'мкрн. Самал 2',
  laptop_specifications: 'Характеристики ноутбуков',
  screen_diagonal: 'Диагональ экрана',
  cpu: 'Процессор',
  ram: 'Оперативная память',
  hdd: 'Хранение данных',
  videocard: 'Видеокарта',
  operation_system: 'ОС',
  choose_theme: 'Выберите тему',
  route_main: 'Главная',
  route_features: "Преимущества",
  route_services: "Услуги",
  route_contacts: "Контакты",
  route_catalogue: "Каталог",
  }