// locales/en.js

export default {
  choose_language: 'Choose language',
  btnIcon: require('../../public/flags-locales/en.png'),
  features: 'Features',
  services: 'Services',
  contacts: 'Contacts',
  catalog: 'Catalogue',
  rental_service: 'Laptops rental',
  in_almaty: 'in Almaty',
  laptop_rental_in_almaty: 'Laptops rental in Almaty',
  the_best_choice_laptop: 'The best technical choosing',
  set_necessary_software: 'Installing required software',
  delivery_or_pickup: 'Delivery or pickup',
  business_class_rental: 'Business rental',
  high_performance_laptops: 'Laptops with high performance',
  laptop_catalog: 'Laptop catalogue',
  rental_cost: 'Rent price',
  rental_price: 'From 5000 tg (depending on the lease term)',
  long_term_rental: 'Long term rental',
  for_long_term_rent: 'For long term rental',
  percent_sale: 'You can get with 80% discount',
  trust_us: 'We are trusted',
  samal_address: 'm/d Samal 2',
  laptop_specifications: 'Laptop specifications',
  screen_diagonal: 'Screen diagonal',
  cpu: 'CPU',
  ram: 'RAM',
  hdd: 'Storage',
  videocard: 'Videocard',
  operation_system: 'OS',
  choose_theme: 'Choose theme',
  route_main: 'Main',
  route_features: "Features",
  route_services: "Services",
  route_contacts: "Contacts",
  route_catalogue: "Catalogue",

  }